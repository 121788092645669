$meeting: #ed1c24 !default;
$debate: #099329 !default;
$consultation: #92278f !default;
$external_event: #ed650b !default;
$participatory_step: #3A4A9F !default;

#calendar{
  .fc-scroller {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
  }
}

.calendar-filters{
  .meeting{
    @include button-style($meeting, $meeting, white);
    &.hollow{
      color: $meeting;
      border-color: $meeting;
      @include button-hollow;
    }
  }
  .debate{
    @include button-style($debate, $debate, white);
    &.hollow{
      color: $debate;
      border-color: $debate;
      @include button-hollow;
    }

  }
  .consultation{
    @include button-style($consultation, $consultation, white);
    &.hollow{
      color: $consultation;
      border-color: $consultation;
      @include button-hollow;
    }
  }
  .external_event{
    @include button-style($external_event, $external_event, white);
    &.hollow{
      color: $external_event;
      border-color: $external_event;
      @include button-hollow;
    }
  }
  .participatory_step{
    @include button-style($participatory_step, $participatory_step, white);
    &.hollow{
      color: $participatory_step;
      border-color: $participatory_step;
      @include button-hollow;
    }
  }
}
